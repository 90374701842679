.SubmitTextInput-button {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10vw;
  width: 80vw;
  height: 7vh;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.05);
  -webkit-filter: drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.15));
}

.SubmitTextInput-button-selected {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: #fda22d;
  border-radius: 10vw;
  width: 80vw;
  height: 7vh;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.05);
}

.SubmitTextInput-input {
  text-align: left;
  color: black;
  margin-left: 15px;
  font-weight: 700;
  font-size: 16px;
  box-shadow: none;
  font-style: italic;
  align-items: center;
  justify-content: center;
}

.SubmitTextInput-input-selected {
  text-align: left;
  color: white;
  margin-left: 15px;
  font-weight: 700;
  font-size: 16px;
  box-shadow: none;
  background-color: #fda22d;
  font-style: none;
}

.SubmitTextInput-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.SubmitTextInput-done-placeholder {
  color: rgba(0, 0, 0, 0.1);
  font-size: 14px;
  margin-right: 15px;
  font-weight: 700;
}

.SubmitTextInput-done {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin-right: 15px;
  font-weight: 600;
}

.SubmitTextInput-done-selected {
  color: white;
  font-size: 14px;
  margin-right: 15px;
  font-weight: 600;
}

.SubmitTextInput-send {
  width: 8vh;
  height: 8vh;
  object-fit: contain;
  -webkit-filter: drop-shadow(0px 5px 3px rgba(226, 76, 118, 0.5));
  filter: drop-shadow(0px 5px 3px rgba(226, 76, 118, 0.5));
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

input {
  background-color: transparent;
  border: 0px solid;
}

inputStyle {
  font-size: 20px;
}

input:focus {
  outline: none;
}
