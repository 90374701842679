.LoadingScreen-title {
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
  }
  
  .LoadingScreen-logo {
  
    height: 15vmin;
    border-radius: 3vmin;
  }
  
  .LoadingScreen-container {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }