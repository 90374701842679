.ChoiceButton {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: #6d40dc;
  border-radius: 2.5vw;
  width: 80vw;
  height: 7vh;
  margin: 3px;
  -webkit-filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.1));
}

.ChoiceButton-selected {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: #ffa32c;
  border-radius: 2.5vw;
  width: 80vw;
  height: 7vh;
  margin: 3px;
}

.topicAndVoteContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.rankContainer {
  background-color: #734cdb;
  border-radius: 2.5vw 0 0 2.5vw;
  height: 100%;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rankContainerSelected {
  background-color: #ffbe2d;
  border-radius: 2.5vw 0 0 2.5vw;
  height: 100%;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChoiceButton-number-text {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 24px;
  font-family: "Bungee";
}

.ChoiceButton-text {
  text-align: left;
  color: white;
  font-weight: 700;
  font-size: 18px;
}

.ChoiceButton-vote-text {
  text-align: center;
  color: #84fafd;
  font-weight: 700;
  font-size: 22px;
  font-family: "Bungee";
}

.ChoiceButton-vote-text-selected {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  font-family: "Bungee";
}
