.App {
  text-align: center;
  align-items: center;
  justify-content: center
}


.App-title {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
}
.App-logo {
  height: 40vmin;
}

.App-preview {

  height: 30vmin;
  border-radius: 5vmin;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

button {
  background-color:  rgba(0,0,0,0);
  border-width: 0;

}

button:hover,
button:focus, 
button:active  {
    background-color: rgba(0,0,0,0);
}
