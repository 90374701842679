.ChoiceScreen-title {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 14px;
  width: 70vw;
  margin-top: 0;
}

.ChoiceScreen-preview {
  height: 45vw;
  width: 75vw;
  border-radius: 5vmin;
  border-style: solid;
  border-width: 3px;
  border-color: white;
  -webkit-filter: drop-shadow(0px 15px 7px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0px 15px 7px rgba(0, 0, 0, 0.2));
}

.ChoiceScreen-fm-title {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 800;
  font-size: 24px;
  margin: 0px;
  font-family: "Bungee";
}

.ChoiceScreen-header-container {
  background-color: transparent;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh;
}

.ChoiceScreen-logo-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

.ChoiceScreen-logo {
  height: 12vmin;
  border-radius: 3vmin;
}

.ChoiceScreen-list {
  width: 100vw;
}

.ChoiceScreen-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: linear-gradient(#5e7af6, #7320a8 10%, #4c2caf 40%);
}

.ChoiceScreen-logo-placeholder {
  width: 100vw;
  height: 12vh;
}

button {
  padding: 0px;
}

.ChoiceScreen-title-highlighted {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: yellow;
  font-weight: 700;
  font-size: 16px;
  width: 70vw;
  margin-top: 0;
}
