.Countdown {
  margin: 5px auto;
  padding-bottom: 0px;
  color: white;
  margin-bottom: 20px;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0px;
}

.Countdown-number {
  font-size: 18px;
  color: white;
  font-weight: 700;
  margin: 0px;
  background-color: #9a47f4;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  padding: 4px;
  padding-top: 6px;
  padding-bottom: 7px;
  border-radius: 5px;
  margin: 2.5px;
  font-family: "Bungee";
  -webkit-filter: drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.1));
}
.Countdown-divider {
  font-family: "Bugnee";
  color: #9a47f4;
  font-size: 30px;
}
