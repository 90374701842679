.DownloadScreen-title {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
  width: 60vw;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
}

.DownloadScreen-lottie {
  position: absolute;
  top: 25vh;
  left: 20vw;
  width: 60vw;
  height: 60vw;
}

.DownloadScreen-sub-title {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 1);
  width: 60vw;
  font-weight: 700;
  font-size: 16px;
}

.DownloadScreen-logo {
  height: 15vmin;
  border-radius: 3vmin;
}

.DownloadScreen-container {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.DownloadScreen-button {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  border-radius: 4vw;
  width: 80vw;
  margin: 10px;
}

.DownloadScreen-button-text {
  color: white;
  font-weight: 700;
  font-size: 16px;
}
