.VoteButton {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(216, 216, 216, 0.8);
  border-radius: 15vw;
  width: 80vw;
  height: 8vh;
  margin: 10px;
  overflow: hidden;
}

.VoteButton-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 15vh;
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.2));
  background-image: url("../../assets/bottom-gradient.png");
}
.VoteButton-button-icon {
  width: 6vw;
  height: 6vw;
  object-fit: contain;
  margin-right: 4vw;
}
.VoteButton-circle-fade {
  width: 16vw;
  height: 16vw;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8vw;
  margin-left: -70vw;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.VoteButton-highlighted {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(226, 76, 118, 1);
  border-radius: 15vw;
  width: 80vw;
  height: 8vh;
  margin: 10px;
  overflow: hidden;
}

.VoteButton-text {
  color: white;
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  font-family: "Bungee";
  font-size: 20px;
  margin: auto;
}
