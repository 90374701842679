.ChoiceList {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.ChoiceList-countdown {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.ChoiceList-container {
  align-items: center;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  width: 100vw;
  flex-direction: column;
  overflow-y: scroll;
}

.ChoiceList-vote-button-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 5vh;
  width: 100vw;
}

.ChoiceList-header-text {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  width: 80vw;
  margin-top: 0px;
  font-family: "Bungee";
}

.ChoiceList-list-header {
  color: black;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 80vw;
  margin-top: 10px;
  padding-right: 5%;
}

.rankTopicContainer {
  color: black;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 40%;
  flex-direction: row;
}

.rankTitleContainer {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
}

.ChoiceList-list-header-text {
  color: #b69cff;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
